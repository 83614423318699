#vlightbox1 {
	width:100%;
	zoom:1;	
}

#vlightbox1 .vlightbox1 div{
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:#000 url(/images/zoom-in1.png) 50% 54% no-repeat;
	opacity:0.6;
	color:#FFF;	
}
#vlightbox1 .vlightbox1 {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
	position:relative;
	vertical-align:top;
	margin:5px;
	font-family:Trebuchet,Tahoma,Arial,sans-serif;
	font-size:11px;
	font-weight:bold;
	text-decoration:none;
	text-align:center;
	outline:none;
	margin: 0;
}

#vlb1imageContainer a {
	display: none !important;
}

#vlightbox1 .vlightbox1 a{
	margin:0;
}
#vlightbox1 .vlightbox1 img{
	display:block;
	border:none;
	margin:0;
}
#vlightbox1 .vlightbox1 div {display:none}

#vlb1overlay{
	position:absolute;
	top:0;
	left:0;
	z-index:190;
	width:100%;
	height:auto;
	background-color:#555555;
}

#vlb1lightbox{
	position:absolute;
	top:20px;
	left:0;
	width:100%;
	z-index:200;
	text-align:center;
	color:#FFFFFF;
	line-height:0;
	padding-top: 20px;
}
#vlb1lightbox td{
	vertical-align:top;
}
#vlb1lightbox a, #vlb1lightbox a:hover {
	border-bottom:none;
	color:#FFFFFF;
	text-decoration:underline;
}

#vlb1lightbox a img{ border:none; }

#vlb1outerImageContainer{
	width:auto;
	height:auto; /* without this line error in IE8 detected */
	margin:0 auto;
	position:relative;
}

#vlb1lightboxImage{
	width:100%;
	height:100%;
}

#vlb1imageContainerMain{
	margin:0 auto;
	overflow:visible;
	position:relative;
	font-size:0;/* ie fix - big info bar*/
}

#vlb1imageContainer{
	width:150px;
	height:30px;
	margin:0 auto;
	overflow:hidden;
	background-color:#fff;
	position:relative;
	font-size:0;/* ie fix - big info bar*/
}

#vlb1loading{
	position:absolute;
	top:40%;
	left:0%;
	height:25%;
	width:100%;
	text-align:center;
	font-size:10px;
	z-index:1;
}
#vlb1loadingLink {
	display:block;
	margin:0 auto;
	padding:0;
	width:32px;
	height:32px;
	background:url(/images/loading.gif) center center no-repeat;
	text-indent:-9999px;
}
#vlb1hoverNav{
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
	z-index:10;
}
#vlb1imageContainer>#hoverNav{ left:0;}
#vlb1prevLinkImg{
	top:50%;
	margin-top:-25px; 
	height: 50px;
	width: 31px;
	position:absolute;
	z-index:20;
	outline-style:none;
	display:block;
	text-indent:-9999px;
	background-position: 0 0;
	opacity:0.8;
	filter:progid:DXImageTransform.Microsoft.Alpha(opacity=80);
	}
#vlb1nextLinkImg{
	top:50%;
	margin-top:-25px; 
	height: 50px;
	width: 31px;
	position:absolute;
	z-index:20;
	outline-style:none;
	display:block;
	text-indent:-9999px;
	background-position: 100% 0;
	background:none;
	opacity:0.8;
	filter:progid:DXImageTransform.Microsoft.Alpha(opacity=80);
	}	
* html #vlb1prevLinkImg,* html #vlb1nextLinkImg{
	background-image:url(data:image/gif;base64,AAAA); /* Trick IE into showing hover */
}
#vlb1prevLinkImg { left: 0px; }
#vlb1nextLinkImg { right: 0px; }
#vlb1prevLinkImg:hover, 
#vlb1prevLinkImg.hover, 
#vlb1prevLinkImg:visited:hover { 
    opacity:1; 
}
#vlb1nextLinkImg:hover, 
#vlb1nextLinkImg.hover, 
#vlb1nextLinkImg:visited:hover { 
	opacity:1; 
} 
#vlb1imageContainerMain:hover #vlb1prevLinkImg{
	background-image:url(/images/prev.png);
	background-position: 90% 50%;
}
#vlb1imageContainerMain:hover #vlb1nextLinkImg{
	background-image:url(/images/next.png);
	background-position: 20% 50%;
}
* html #vlb1prevLinkImg{
	background-image:url(//images/prev.png);
}
* html #vlb1nextLinkImg{
	background-image:url(/images/next.png);
} 

#vlb1imageDataContainer{
	width: auto;
	position: absolute;
	z-index:21;
	bottom: 20px;	
	left: 5px;	
}

#vlb1imageData{
	font-family: 'Oswald',Arial,Helvetica,sans-serif;
	font-size: 22px;
    line-height: 24px; 	
	text-transform: uppercase; 
	color: #E9E9E9;
	background-color: rgba(0, 0, 0, 0.9);
	margin: 0 15px 0 20px; 
	overflow:visible;
	position: relative;
	z-index:21;
	text-align:center;
	border-left:5px solid #568235
}
#vlb1imageDetails{ width:100%; padding: 0;}
#vlb1caption{ display:block;text-align:left; padding: 12px;}
#vlb1numberDisplay{ display:none;text-align:right;}
#vlb1detailsNav{display:none;}
#vlb1prevLinkDetails, #vlb1nextLinkDetails{display:none;}

#vlb1imageDataContainer{
    width: auto;
    position: absolute;
    z-index:21;
    bottom: 20px;    
    left: 5px;    
}

#vlb1close{
	position:relative;
	width:100%;
	height:0;
	z-index:21;
}
#vlb1closeLink {
	position:absolute;
	top:7px;
	right:7px;
	display:block;
	outline-style:none;
	margin:0;
	padding:0;
	text-decoration:none;
	width:23px;
	height:21px;
/*	background:url(close3.png) no-repeat;*/
	_background-image:none;
	_filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='index_files/vlb_files1/close.png', sizingMethod='scale');
	opacity:0.8;
	filter:progid:DXImageTransform.Microsoft.Alpha(opacity=80);
}

#vlb1closeLink:hover {
	opacity:1;	
}  

.clearfix:after { content:"."; display:block; height:0; clear:both; visibility:hidden;}
* html>body .clearfix {display:inline-block; width:100%;}

* html .clearfix {
	/* Hides from IE-mac \*/
	height:1%;
	/* End hide from IE-mac */
}	

#vlb1outerImageFrame{
	border:5px solid red;
	height:100%;
	width:100%;
	position:absolute;
}

#vlb1outerImageContainer{
	overflow:visible;
	margin-bottom: 5px;
}
#vlb1outerImageContainer td{
	text-align:center;
	padding:0;
}

#vlb1lightboxFrameBody{
	background-color:transparent;
}
.vlb{display:none;}
#vlb1outerImageContainer td, #vlb1outerImageContainer tr{
	font-size:0;
	border:0 none;
}

#vlb1outerImageContainer td.tl, #vlb1outerImageContainer td.br{
	height:5px;
	width:5px;
}


/*# sourceMappingURL=3.min.css.map */
